<template>
    <div>
      <AdminComponent></AdminComponent>
    </div>
  </template>
  
  <script>
  import AdminComponent from '@/components/AdminComponent.vue';
  export default {
    name: "AdminPage",
    components: {
      AdminComponent,
    },
  };
  </script>
  